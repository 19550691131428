import PropTypes from 'prop-types';
import Image from 'next/image';
import clsx from 'clsx';

const defaultProps = {
  className: null,
};

const propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const cloudinaryLoader = ({ src }) => {
  if (/^(f|ht)tps?:\/\//i.test(src)) {
    return src;
  }

  return `https://res.cloudinary.com/aibesorg/image/upload/f_auto,q_auto,fl_lossy,f_avif/${src}`;
};

export default function RemoteImage({ src, alt, className, ...rest }) {
  return (
    <Image
      src={src}
      alt={alt}
      loader={cloudinaryLoader}
      className={clsx('block h-auto max-w-full', className)}
      placeholder="blur"
      blurDataURL={`data:image/svg+xml;base64,`}
      {...rest}
    />
  );
}

RemoteImage.defaultProps = defaultProps;
RemoteImage.propTypes = propTypes;
