import PropTypes from 'prop-types';
import clsx from 'clsx';

const defaultProps = {
  offset: false,
  className: '',
  children: null,
};

const propTypes = {
  offset: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default function Section({ offset, className, children }) {
  return (
    <section className={clsx(offset && 'py-10 lg:py-16', className)}>
      {children}
    </section>
  );
}

Section.defaultProps = defaultProps;
Section.propTypes = propTypes;
