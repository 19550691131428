import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import Link from '@/components/Link';
import Image from '@/components/Image';
import RemoteImage from '@/components/Image/RemoteImage';

const defaultProps = {
  alt: '',
  title: '',
  url: '',
  active: false,
  color: 'gray-100',
  isNew: false,
  useRemote: true,
};

const propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  url: PropTypes.string,
  alt: PropTypes.string,
  active: PropTypes.bool,
  image: PropTypes.string.isRequired,
  imageSources: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isNew: PropTypes.bool,
  useRemote: PropTypes.bool,
};

export default function HeroImage({
  id,
  title,
  color,
  url,
  image,
  imageSources,
  alt,
  active,
  isNew,
  useRemote,
}) {
  let imageContent;

  if (image.indexOf('//') !== -1 || useRemote) {
    imageContent = (
      <RemoteImage src={image} alt={alt} width={710} height={800} />
    );
  } else {
    imageContent = (
      <Image
        title={title}
        src={image}
        sources={imageSources}
        className="pointer-events-none select-none lg:h-full"
        alt={alt}
        width={710}
        height={800}
      />
    );
  }

  return (
    <div
      key={id}
      className={clsx(
        'relative max-h-full lg:absolute lg:inset-0 lg:mt-2 lg:h-full lg:w-full',
        active === false && 'z-10',
        active === true && 'z-20'
      )}
    >
      <Transition show={active} unmount={false}>
        <Transition.Child
          enter="transition ease-in-out duration-1000 transform"
          enterFrom="opacity-0 translate-y-2 translate-x-4 scale-95"
          enterTo="opacity-100 translate-y-0 translate-x-0 scale-100"
          leave="transition ease-in-out duration-1000 transform"
          leaveFrom="opacity-100 translate-x-0 scale-100"
          leaveTo="opacity-0 translate-x-2 scale-95"
          unmount={false}
        >
          <div className="pointer-events-none relative z-0 max-h-full select-none lg:h-full">
            {imageContent}
          </div>
        </Transition.Child>
        <div className="relative z-20 lg:absolute lg:bottom-0 lg:right-0 lg:mr-10">
          <Transition.Child
            enter="transition ease-in-out duration-1000 transform transform-gpu"
            enterFrom="translate-y-10 opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="translate-y-10 opacity-0"
            unmount={false}
          >
            {isNew && (
              <div className="mb-2 inline-flex rounded-full bg-green-500 bg-gradient-to-t from-green-600 to-green-500 px-4 text-xs font-semibold leading-6 text-white shadow-sm ring-1 ring-black ring-opacity-10 lg:text-sm">
                Nueva
              </div>
            )}
            <Link
              href={url}
              className="relative flex cursor-pointer rounded-full shadow-lg transition duration-300 ease-in-out hover:shadow-xl lg:shadow-xl lg:hover:shadow-2xl"
            >
              <div className="inline-flex flex-1 select-none items-center space-x-2 rounded-full bg-gray-900 bg-gradient-to-t from-black to-gray-900 py-2 pr-4 pl-2 text-xs font-medium leading-5 text-gray-300 lg:text-sm">
                <span
                  className={clsx('h-5 w-5 select-none rounded-full', color)}
                />
                <span className="block">{title}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-white text-opacity-50"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </Link>
          </Transition.Child>
        </div>
      </Transition>
    </div>
  );
}

HeroImage.defaultProps = defaultProps;
HeroImage.propTypes = propTypes;
