export default [
  {
    id: 'official-product-backlog-management-certification',
    title: 'Official Product Backlog Management Certification',
    color: 'bg-teal-700',
    url: '/certificaciones/official-product-backlog-management-certification/',
    image: 'w_820,h_910/v1705344536/personas/twyy7ze6bqo0gipfjoyn.png',
    isNew: true,
  },
  {
    id: 'official-agile-metrics-certification',
    title: 'Official Agile Metrics Certification',
    color: 'bg-cyan-600',
    url: '/certificaciones/official-agile-metrics-certification/',
    image:
      'w_820,h_910/v1682566861/personas/official-agile-metrics-certification_lyjqjt.png',
  },
  {
    id: 'official-kanban-practitioner-certification',
    title: 'Official Kanban Practitioner Certification',
    color: 'bg-fuchsia-600',
    url: '/certificaciones/official-kanban-practitioner-certification/',
    image:
      'w_820,h_910/v1642658497/personas/official-kanban-certification_df3tah.png',
  },
  {
    id: 'official-scrum-fundamentals-certification',
    title: 'Official Scrum Fundamentals Certification',
    color: 'bg-yellow-600',
    url: '/certificaciones/official-scrum-fundamentals-certification/',
    image:
      'w_820,h_910/v1611708369/personas/official-scrum-fundamentals-certification_dm3r9u.png',
  },
  {
    id: 'official-scrum-master-certification',
    title: 'Official Scrum Master Certification',
    color: 'bg-blue-500',
    url: '/certificaciones/official-scrum-master-certification/',
    image:
      'w_820,h_910/v1610683720/personas/official-scrum-master-certification_lov3hh.png',
    sources: {
      'image/webp':
        'v1610683717/personas/official-scrum-master-certification_kwqtco.webp',
    },
  },
  {
    id: 'official-product-owner-certification',
    title: 'Official Product Owner Certification',
    color: 'bg-green-400',
    url: '/certificaciones/official-product-owner-certification/',
    image:
      'w_820,h_910/v1610683733/personas/official-product-owner-certification_pvac3m.png',
    sources: {
      'image/webp':
        'v1610683729/personas/official-product-owner-certification_kjjlhv.webp',
    },
  },
  {
    id: 'official-scrum-developer-certification',
    title: 'Official Scrum Developer Certification',
    color: 'bg-yellow-500',
    url: '/certificaciones/official-scrum-developer-certification/',
    image:
      'w_820,h_910/v1611711515/personas/official-scrum-developer-certification_q0diio.png',
  },
  {
    id: 'official-scrum-trainer-certification',
    title: 'Official Scrum Trainer Certification',
    color: 'bg-purple-500',
    url: '/certificaciones/official-scrum-trainer-certification/',
    image:
      'w_820,h_910/v1610683720/personas/official-scrum-trainer-certification_vcylug.png',
    sources: {
      'image/webp':
        'v1610683717/personas/official-scrum-trainer-certification_uk3xyh.webp',
    },
  },
  {
    id: 'official-agile-coach-certification',
    title: 'Official Agile Coach Certification',
    color: 'bg-red-500',
    url: '/certificaciones/official-agile-coach-certification/',
    image:
      'w_820,h_910/v1610683730/personas/official-agile-coach-certification_ng96yw.png',
    sources: {
      'image/webp':
        'v1610683724/personas/official-agile-coach-certification_ew6ncs.webp',
    },
  },
  {
    id: 'official-scrum-master-certification-secondary',
    title: 'Official Scrum Master Certification',
    color: 'bg-blue-500',
    url: '/certificaciones/official-scrum-master-certification/',
    image:
      'w_820,h_910/v1610683726/personas/official-scrum-master-certification-secondary_k0knok.png',
    sources: {
      'image/webp':
        'v1610683728/personas/official-scrum-master-certification-secondary_jknkty.webp',
    },
  },
  {
    id: 'official-product-owner-certification-secondary',
    title: 'Official Product Owner Certification',
    color: 'bg-green-400',
    url: '/certificaciones/official-product-owner-certification/',
    image:
      'w_820,h_910/v1610683734/personas/official-product-owner-certification-secondary_cajwct.png',
    sources: {
      'image/webp':
        'v1610683734/personas/official-product-owner-certification-secondary_ukkosx.webp',
    },
  },
  {
    id: 'official-scrum-developer-certification-secondary',
    title: 'Official Scrum Developer Certification',
    color: 'bg-yellow-500',
    url: '/certificaciones/official-scrum-developer-certification/',
    image:
      'w_820,h_910/v1611709029/personas/official-scrum-developer-certification-secondary_yvsvrw.png',
  },
  {
    id: 'official-scrum-trainer-certification-secondary',
    title: 'Official Scrum Trainer Certification',
    color: 'bg-purple-500',
    url: '/certificaciones/official-scrum-trainer-certification/',
    image:
      'w_820,h_910/v1610683720/personas/official-scrum-trainer-certification-secondary_mlpqsw.png',
    sources: {
      'image/webp':
        'v1610683724/personas/official-scrum-trainer-certification-secondary_bmolry.webp',
    },
  },
  {
    id: 'official-agile-coach-certification-secondary',
    title: 'Official Agile Coach Certification',
    color: 'bg-red-500',
    url: '/certificaciones/official-agile-coach-certification/',
    image:
      'w_820,h_910/v1610683731/personas/official-agile-coach-certification-secondary_oipgll.png',
    sources: {
      'image/webp':
        'v1610683731/personas/official-agile-coach-certification-secondary_wyliwf.webp',
    },
  },
];
