import Container from '@/components/Container';
import HeroCarousel from './HeroCarousel';
import Link from '@/components/Link';

export default function Hero() {
  return (
    <div
      className="lg:hero-min-h xl:hero-min-h relative z-10 overflow-hidden bg-gradient-to-tr from-green-100 to-white py-10 lg:flex"
      role="banner"
    >
      <Container className="relative z-50">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-50 lg:col-span-7 lg:flex lg:items-center">
            <div className="space-y-4 py-10 leading-tight lg:py-14 xl:pb-20 lg:pr-20">
              <h1 className="leading-12 mb-8 break-words text-5xl font-extrabold tracking-tight text-gray-900 md:text-5xl xl:text-6xl">
                Somos la Asociación Iberoamericana&nbsp;
                <div className="hidden lg:block" />
                de&nbsp;
                <span className="text-green-500 lg:font-black">Scrum</span>
              </h1>
              <div className="text-lg text-gray-800 lg:pr-20 lg:text-xl">
                <div className="leading-8">
                  Una organización Internacional e independiente con enfoque
                  global y académico, que ofrece certificaciones reconocidas a
                  nivel internacional, basadas en el Manifiesto Ágil y los
                  principios de Scrum.
                </div>
              </div>
              <div className="flex flex-col space-y-6 pt-5 md:flex-row md:items-center md:space-y-0 md:space-x-4">
                <div className="flex">
                  <Link
                    href="/certificaciones"
                    className="inline-flex justify-center whitespace-nowrap rounded-full bg-green-500 px-10 py-3 text-center text-xl font-semibold leading-none text-white hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-800 focus:ring-opacity-20 md:text-lg lg:text-xl"
                  >
                    Certifícate hoy mismo
                  </Link>
                </div>
                <div className="flex">
                  <Link
                    href="/clases"
                    className="inline-flex items-center justify-center px-2 py-4 text-center leading-none text-gray-900 hover:text-gray-700 focus:outline-none"
                  >
                    <div className="text-xl font-bold leading-none md:text-lg ">
                      Encuentra un curso
                    </div>
                    <svg
                      className="-mb-1 h-6 w-6 text-gray-900 md:h-5 md:w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HeroCarousel />
      </Container>
      <div className="absolute top-0 bottom-0 right-0 z-0 hidden w-full lg:block">
        <div className="absolute top-0 bottom-0 right-0 z-10 mt-20 w-2/5 -skew-y-6 transform rounded-tl-lg bg-gradient-to-l from-green-500 to-transparent" />
        <div className="absolute top-0 bottom-0 right-0 z-20 mt-40 -mb-20 w-2/4 -skew-y-6 transform rounded-tl-lg bg-gradient-to-l from-green-500 to-transparent" />
        <div className="absolute top-0 bottom-0 right-0 z-30 mt-80 mb-10 w-2/6 -skew-y-6 transform rounded-tl-lg bg-gradient-to-l from-green-600 to-transparent opacity-50" />
      </div>
    </div>
  );
}
