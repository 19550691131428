import PropTypes from 'prop-types';
import Container from '@/components/Container';
import Image from '@/components/Image';
import RemoteImage from '@/components/Image/RemoteImage';
import clsx from 'clsx';

const defaultProps = {
  className: 'lg:px-20',
  customers: [],
};

const propTypes = {
  className: PropTypes.string,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default function CustomerLogos({ className, customers }) {
  return (
    <Container className={clsx(className)}>
      <div className="flex items-center justify-center">
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-4 justify-center">
          {customers.map(({ logo, title, url }, idx) => (
            <div key={`customer-logo-${idx}-with-${url}`}>
              {typeof logo === 'string' ? (
                <RemoteImage src={logo} alt={title} width={300} height={190} />
              ) : (
                <Image title={title} {...logo} />
              )}
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}

CustomerLogos.defaultProps = defaultProps;
CustomerLogos.propTypes = propTypes;
