import Section from '@/components/Section';
import Container from '@/components/Container';
import Link from '@/components/Link';
import Hero from '@/view/Hero';
import Globe from '@/domain/Globe';
import CustomerLogos from '@/domain/CustomerLogos';
import * as customers from '@/domain/customers';

export default function Home() {
  return (
    <div className="bg-white">
      <Hero />
      <Section className="bg-white py-10 lg:py-20">
        <Container className="space-y-8">
          <h2 className="text-center text-xl tracking-tight lg:text-2xl">
            Con la confianza de empresas e instituciones reconocidas
          </h2>
          <CustomerLogos
            customers={[
              customers.udla,
              customers.technisys,
              customers.procredit,
              customers.nestleSimple,
              customers.modernaAlimentos,
              customers.iess,
            ]}
          />
          <div className="text-center">
            <Link
              className="inline-flex items-center space-x-2 text-lg font-semibold text-green-500 hover:text-green-600 lg:text-xl"
              href="/clientes"
            >
              <span>Explorar más</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </div>
        </Container>
      </Section>
      <Section className="relative overflow-hidden bg-white bg-gradient-to-tl from-green-100 to-gray-100">
        <Container className="relative z-20 pt-10 lg:pt-20 lg:pb-20 xl:pt-32 xl:pb-32">
          <div className="mx-auto w-full space-y-10">
            <div className="grid grid-cols-1 lg:grid-cols-12">
              <div className="lg:col-span-8">
                <div className="space-y-6">
                  <h3 className="text-lg font-bold tracking-tight text-green-500 lg:text-xl">
                    Nosotros
                  </h3>
                  <h2 className="text-4xl font-bold tracking-tight md:leading-relaxed lg:text-4xl lg:font-extrabold xl:text-6xl">
                    Somos la certificadora por excelencia preferida por
                    hispanohablantes
                  </h2>
                  <div className="text-lg text-gray-700 lg:pr-60 lg:text-xl">
                    <div className="leading-relaxed">
                      Enseñamos a profesionales y empresas a aplicar los
                      principios del desarrollo ágil y certificamos sus
                      competencias en el dominio de Scrum.
                    </div>
                  </div>
                  <div className="flex items-center lg:pt-4">
                    <Link
                      href="/nosotros"
                      className="inline-flex justify-center whitespace-nowrap rounded-full bg-green-500 px-10 py-3 text-center text-xl font-semibold leading-none text-white hover:bg-green-600 focus:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-800 focus:ring-opacity-20 md:text-lg lg:text-xl"
                      role="button"
                    >
                      <div className="font-semibold">
                        Conoce más sobre nosotros
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="relative overflow-hidden lg:absolute lg:top-0 lg:bottom-0 lg:right-0 lg:-mr-80 lg:flex lg:w-full lg:items-center lg:justify-center xl:w-3/4">
          <div
            className="relative -mt-10 flex items-end justify-center pb-20 lg:-mt-40"
            style={{ minHeight: 350 }}
          >
            <Globe />
          </div>
        </div>
      </Section>
    </div>
  );
}

Home.layoutProps = {
  meta: {
    description:
      'Ayudamos a profesionales y empresas a aplicar los principios del desarrolo ágil y certificamos sus competencias en el dominio del framework Scrum.',
  },
};
