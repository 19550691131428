export const iess = {
  logo: 'v1642656757/clientes/iess_jov8bv.png',
  title: 'Instituto Ecuatoriano de Seguridad Social',
  url: 'https://www.iess.gob.ec/',
};

export const nestle = {
  logo: 'v1642656870/clientes/nestle_dgfaal.png',
  title: 'Nestle Ecuador',
  url: 'https://www.nestle.com.ec/',
};

export const nestleSimple = {
  logo: 'v1642656903/clientes/nestle-simple_j6jz9n.png',
  title: 'Nestle Ecuador',
  url: 'https://www.nestle.com.ec/',
};

export const soltein = {
  logo: 'v1610716328/clientes/soltein_xie3pe.png',
  title: 'Soltein',
  url: 'https://www.soltein.net/',
};

export const soyHincha = {
  logo: 'v1610716328/clientes/soy-hincha_rualzx.png',
  title: 'Soy Hincha',
  url: 'https://www.soyhincha.com/',
};

export const udla = {
  logo: 'v1642656789/clientes/udla_ljwmwd.png',
  title: 'Universidad de las Américas',
  url: 'https://www.udla.edu.ec/',
};

export const modernaAlimentos = {
  logo: 'v1642656833/clientes/moderna-alimentos_s8ibnv.png',
  title: 'Alimentos Moderna',
  url: 'https://www.moderna.com.ec/',
};

export const stackbuilders = {
  logo: 'v1615002897/clientes/stackbuilders_b5h22c.png',
  title: 'Stack Builders',
};

export const technisys = {
  logo: 'v1642492527/clientes/technisys_tpocwc.png',
  title: 'Technisys',
};

export const salica = {
  logo: 'v1642492527/clientes/salica_vjaqro.png',
  title: 'Salica',
};

export const klever = {
  logo: 'v1642494547/clientes/klever_pweeo6.png',
  title: 'Klever Team',
};

export const farmaenlace = {
  logo: 'v1642492527/clientes/farmaenlace_bamb3a.png',
  title: 'Farmaenlace',
};

export const megaprofer = {
  logo: 'v1642492965/clientes/megaprofer_tafkg9.png',
  title: 'Megaprofer',
};

export const procredit = {
  logo: 'v1642656694/clientes/banco-procredit_e89oem.png',
  title: 'Banco ProCredit',
};
