import { useState } from 'react';
import PropTypes from 'prop-types';
import useInterval from '@/hooks/useInterval';
import usePageVisibility from '@/hooks/usePageVisibility';
import HeroImage from './HeroImage';
import personas from './pictures.remote';

const defaultProps = {
  delay: 7000,
};

const propTypes = {
  delay: PropTypes.number,
};

export default function HeroCarousel({ delay }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const isPageVisible = usePageVisibility();

  useInterval(() => {
    if (isPageVisible) {
      let nextIndex = activeIndex + 1;
      if (nextIndex > personas.length - 1) {
        nextIndex = 0;
      }
      setActiveIndex(nextIndex);
    }
  }, delay);

  return (
    <div className="z-0 hidden w-full lg:absolute lg:right-0 lg:bottom-0 lg:top-0 lg:z-0 lg:block">
      <div className="relative z-20 lg:absolute lg:top-0 lg:bottom-0 lg:right-0 lg:w-2/4">
        {personas.map(
          ({ id, title, color, url, image, sources, isNew }, index) => (
            <HeroImage
              key={id}
              id={id}
              title={title}
              color={color}
              url={url}
              image={image}
              imageSources={sources}
              alt={title}
              isNew={!!isNew}
              active={activeIndex === index}
            />
          )
        )}
      </div>
    </div>
  );
}

HeroCarousel.defaultProps = defaultProps;
HeroCarousel.propTypes = propTypes;
