import { useEffect, useRef } from 'react';
import createGlobe from 'cobe';

export default function Globe() {
  const canvasRef = useRef();

  useEffect(() => {
    let phi = 0;

    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: 1320 * 1.6,
      height: 1320 * 1.6,
      phi: 1,
      theta: -0.1,
      dark: 0,
      diffuse: 2.94,
      mapSamples: 20000,
      mapBrightness: 12,
      baseColor: [1, 1, 1],
      markerColor: [0, 0.8, 0],
      glowColor: [0.8, 0.8, 0.8],
      markers: [
        { location: [46.856283, -71.4817758], size: 0.1 },
        { location: [27.2789861, -85.1563494], size: 0.05 },
        { location: [40.4378698, -3.8196209], size: 0.06 },
        { location: [-12.0487766, -77.0442662], size: 0.061 },
        { location: [-33.4421472, -70.6851123], size: 0.031 },
        { location: [9.6338037, -84.5570323], size: 0.1 },
        { location: [19.39068, -99.2836991], size: 0.031 },
        { location: [-16.5207007, -68.1941181], size: 0.021 },
        { location: [-25.2968361, -57.6681295], size: 0.031 },
      ],
      onRender: (state) => {
        state.phi = phi;
        phi += 0.001;
      },
    });

    return () => {
      globe.destroy();
    };
  }, []);

  return (
    <div
      className="absolute bottom-0 transform"
      style={{ width: 1100, height: 1100, transform: 'translateY(60%)' }}
    >
      <canvas
        className="w-full h-full outline-none"
        ref={canvasRef}
        width={1320}
        height={1320}
      />
    </div>
  );
}
